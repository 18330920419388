import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import styled from 'styled-components'
import { ChildSectionShortWidth, GlassCard, H1, H2 } from '../../components'
import { binderConfig } from '../../features/chainbinders/config'
import Author from '../../images/author.svg'
import LinkIcon from '../../images/blue-link.svg'
import BNDIcon from '../../images/bnd-token-circle.svg'
import DokiIcon from '../../images/doki-logo.svg'
import Background from '../../images/single-binder-bg.svg'
interface Props {
  params: any
  availableCopies?: number
  ethValue?: number
}

const SingleBinder: React.FC<Props> = ({ params }) => {
  const [showBorder, setShowBorder] = useState(true)
  const { t, i18n } = useTranslation()
  const [story, setStory] = useState('')
  const binderKey = params.key
  useEffect(() => {
    import(`../../markdown/${i18n.language}/${binderKey}.md`)
      .then((module) => {
        fetch(module.default)
          .then((res) => res.text())
          .then((txt) => setStory(txt))
      })
      .catch((e) => {
        console.error('[SingleBinder][useEffect]', e)
      })
  })

  const handleImgBorders = () => {
    setShowBorder(!showBorder)
  }
  const { card, noBg, artist, artistRef } = binderConfig[binderKey]

  return (
    <StyledParent>
      <LongSection>
        <TextGroup>
          <H1>{t(binderKey)}</H1>
          <LegendaryTitle>{t('legendary')}</LegendaryTitle>
        </TextGroup>

        <ImgContainer>
          {showBorder ? (
            <StyledShiny img={card}></StyledShiny>
          ) : (
            <NoBorderImg src={noBg} alt="card img" />
          )}

          <CardButtons>
            <SmallButton onClick={handleImgBorders}>
              {t('singleBinderArt')}
            </SmallButton>
          </CardButtons>
        </ImgContainer>

        <CardStats>
          <StyledH3
            color="#00D1FF"
            rel="noreferrer"
            target="_blank"
            href={artistRef}
          >
            <BNDIconImg src={LinkIcon} />
            {artist}
          </StyledH3>
          <StyledH3
            color="#C173FF"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/fillbeforeshill"
          >
            <BNDIconImg src={Author} />
            Roy Blackstone
          </StyledH3>
          <StyledH3
            color="#FF97C3"
            target="_blank"
            rel="noreferrer"
            href="https://dokidoki.finance"
          >
            <BNDIconImg src={DokiIcon} />
            {t('roll')}
          </StyledH3>

          <StyledH3 color="#00FFC2">
            <BNDIconImg src={BNDIcon} />
            {/* {t('view nft')} */}
            {t('comingSoon')}
          </StyledH3>
        </CardStats>

        <StoryScroll>{story && <Markdown>{story}</Markdown>}</StoryScroll>
      </LongSection>
    </StyledParent>
  )
}

export default SingleBinder

const SmallButton = styled.button`
  border: 1px solid white;
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem;
  border-radius: 18px;
`

const CardButtons = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`

const TextGroup = styled(GlassCard)`
  grid-area: text;
  padding: 1rem;
  > h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
  }
  > h1 {
    margin-bottom: 0rem;
  }
`
const StyledParent = styled.section`
  background: url(${Background}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 5rem;
  padding-top: 3rem;
  width: 100vw;
`
const StoryScroll = styled(GlassCard)`
  height: 50rem;
  color: white;
  overflow: auto;

  grid-area: story;

  > h1 {
    margin-top: 1rem;
    text-align: center;
  }

  > h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  > p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-indent: 2rem;
    line-height: 175%;
  }

  > .center {
    text-align: center;
  }

  > p > strong {
    text-align: center;
  }

  > p > code {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const LongSection = styled(ChildSectionShortWidth)`
  display: grid;
  grid-template-rows: auto;
  row-gap: 2rem;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  grid-template-areas:
    'text'
    'binder'
    'stats'
    'story';
`

const ImgContainer = styled(GlassCard)`
  display: flex;
  justify-content: center;
  align-items: center;

  grid-area: binder;

  position: relative;
`

const CardStats = styled.div`
  grid-area: stats;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 1rem;
`

const BNDIconImg = styled.img`
  margin-right: 1rem;

  @media (min-width: 200px) {
    width: 2rem;
  }

  @media (min-width: 1200px) {
    width: 2.5rem;
  }
`

const StyledH3 = styled.a`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;

  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;

  font-weight: 500;
  font-family: 'Open Sans', sans-serif;

  @media (min-width: 200px) {
    font-size: 1.275rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (min-width: 750px) {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.725rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

const LegendaryTitle = styled(H2)`
  color: #ff9900;
`

const NoBorderImg = styled.img`
  @media (min-width: 200px) {
    width: 100%;
    height: auto;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: calc(476px * 2);
  }
`

interface ImgProps {
  img: any
}

const StyledShiny = styled.div<ImgProps>`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: inline-block;
  vertical-align: middle;
  margin: 20px 10px;

  @media (min-width: 200px) {
    width: 268px;
    height: 476px;
  }

  @media (min-width: 1200px) {
    width: calc(268px * 2);
    height: calc(476px * 2);
  }
`
